@tailwind base;
@tailwind components;
@tailwind utilities;

/* GOOGLE FONTS */

@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400&family=Inter:wght@200;300;400&family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400&family=Roboto:wght@300;400&family=Ubuntu:wght@300;400;500&display=swap");

/* 
font-family: 'Archivo', sans-serif;
font-family: 'Inter', sans-serif;
font-family: 'Nunito Sans', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Ubuntu', sans-serif;
 */

.cf-headings,h1,h2,h3,h4,h5,h6 {
  font-family: 'Roboto', sans-serif;
}
.cf-body,body {
  font-family: 'Nunito Sans', sans-serif;
}
.cf-links,a {
  font-family: 'Inter', sans-serif;
}
.cf-buttons,button {
  font-family: 'Ubuntu', sans-serif;
}
.cf-errors, .cf-success, .cf-warnings{
  font-family: 'Archivo', sans-serif;
}
.cf-info {
  font-family: "Ubuntu", sans-serif;
}

body {
  margin: 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scrollbars */
body::-webkit-scrollbar {
  width: 10px;
  height: 2px; /* Added for the bottom scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(70, 70, 70);
  border-radius: 10px;
}

/* Bottom scrollbar thumb */
body::-webkit-scrollbar-thumb:horizontal {
  background-color: rgb(70, 70, 70);
  border-radius: 10px;
}
