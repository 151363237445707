.pagination {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    list-style: none;
}
  
.pagination li {
    margin: 0 5px;
}
  
.pagination li a {
    color: #4b5563;
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}
  
.pagination li a:hover {
    background-color: #4b5563;
    color: white;
}
  
.pagination li.active a {
    background-color: #4b5563;
    color: white;
    border: 1px solid #4b5563;
}
  
.pagination li.disabled a {
    color: #ccc;
    cursor: not-allowed;
}
  
.pagination li.previous a,
.pagination li.next a {
    font-weight: bold;
}
  
.pagination li.break a {
    cursor: default;
    border: none;
}
  